<template>
  <div class="phone">
    <div class="main">
      <MiTitle :title="$t('record.phone')" />
      <div class="body">
        <h2 class="title">{{ $t('recharge.operator') }}</h2>
        <ul class="air-gr air-gap air-gr-fit">
          <li
            v-for="item in rechargePhoneInfo"
            :key="item.id"
            :class="{ active: item.id === carrier }"
            @click="carrier = item.id"
          >
            {{ item.name }}
          </li>
        </ul>
        <h2 class="title">{{ $t('recharge.amount') }}</h2>
        <ul class="air-gr air-gap air-gr-fit">
          <li
            v-for="item in counts"
            :key="item"
            :class="{ active: item === chargeFee }"
            @click="chargeFee = item"
          >
            <span class="count">{{ item }}</span>
            <span>{{ $tc('common.unit', item) }}</span>
          </li>
        </ul>
      </div>
      <ul class="list">
        <li v-for="(item, index) in $t('recharge.desc')" :key="index">
          <span>·</span> {{ item }}
        </li>
      </ul>
    </div>
    <div class="footer">
      <router-link
        :to="{
          path: '/phone-next',
          query: { carrier, chargeFee }
        }"
      >
        <mi-button>{{ $t('common.rechargeBtn') }}</mi-button>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { MiTitle, MiButton } from '@/components'

export default {
  components: {
    MiTitle,
    MiButton
  },
  data() {
    return {
      carrier: 'cmcc',
      chargeFee: 10
    }
  },
  computed: {
    ...mapState({
      rechargePhoneInfo: state => state.recharge.rechargePhoneInfo
    }),
    counts() {
      return (
        this.rechargePhoneInfo.find(v => v.id === this.carrier)?.counts || []
      )
    }
  },
  created() {
    if (!this.rechargePhoneInfo.length) {
      this.$store.dispatch('rechargeGetTypes')
    }
  },
  watch: {
    carrier() {
      this.chargeFee = this.rechargePhoneInfo.find(
        v => v.id === this.carrier
      )?.counts[0]
    }
  }
}
</script>

<style lang="scss" scoped>
.phone {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .body {
    padding: 60px 0px 0;
    .title {
      padding-left: 14px;
      color: #8c93b0;
      font-size: 36px;
    }
    ul {
      margin: 39px 0 40px;
      li {
        height: 218px;
        text-align: center;
        line-height: 218px;
        background: rgba(13, 132, 255, 0.1);
        @include round(48px);
        color: #0d84ff;
        font-weight: 500;
        font-size: 48px;
        span {
          font-weight: 500;
          font-size: 38px;
        }
        .count {
          font-family: Mitype;
          font-weight: 600;
          font-size: 66px;
        }
      }
      .active {
        color: #fff;
        background: #0d84ff;
      }
    }
  }
  .list {
    padding: 0 30px 80px;
    color: rgba(0, 0, 0, 0.4);
    font-size: 36px;
    li {
      display: flex;
      align-items: center;
      span {
        margin-right: 20px;
      }
    }
  }
  .footer {
    padding: 0 80px 90px;
  }
}
</style>
